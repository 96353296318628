import './App.css';
import 'normalize.css';
import { Routes, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import Home from './components/Home';
import Play from './components/Play';
import Watch from './components/Watch';
import Standby from './components/Standby';
import kvImage from './img/KV.png';
import GameDetail from './components/GameDetail';

const stackStyle = {
    background: `url(${kvImage}) center/cover no-repeat`,
    height: '100vh',
    maxWidth: '400px',
};

function App() {
    return (
        <div className="App">
            <Container fixed sx={stackStyle}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Home />} />
                    <Route path="/play" element={<Play />} />
                    <Route path="/watch" element={<Watch />} />
                    <Route path="/game/:id" element={<GameDetail />} />
                    <Route path="/standby" element={<Standby />} />
                </Routes>
            </Container>
        </div>
    );
}

export default App;
