import axios from 'axios';
import { URL } from '../constants';

export function getUsers() {
    return axios
        .get(`${URL}/users`)
        .then((response) => {
            if (response.status === 200) {
                const data = response.data;
                console.log(data);
                return data;
            } else {
                console.log('リクエストが失敗しました。');
                return null;
            }
        })
        .catch((error) => {
            console.error('リクエストが失敗しました：', error);
            return null;
        });
}

export async function createGame(json) {
    console.log(URL);
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: json, // ensure json is in string format
    };

    try {
        const response = await fetch(`${URL}/game`, options);

        if (!response.ok) {
            // check if HTTP status is not ok (200)
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        console.log(data);
        return JSON.stringify(data); // return the data for further use
    } catch (error) {
        console.error('Error:', error);
        throw error; // re-throw the error to be caught and handled outside
    }
}

export async function distributionGame(json) {
    try {
        const response = await fetch(`${URL}/game/${json.game_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(json),
        });

        const text = await response.text();

        if (!response.ok) {
            console.log('call response failed');
            throw new Error('Network response was not ok');
        }

        const responseData = JSON.parse(text);
        console.log(responseData);
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // re-throw the error to be caught and handled outside
    }
}
export async function distributionGameDokkiri(json) {
    console.log('ドッキリお題を配ったよ！');
    try {
        const response = await fetch(`${URL}/game/${json.game_id}/dokkiri`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(json),
        });

        const text = await response.text();

        if (!response.ok) {
            console.log('call response failed');
            throw new Error('Network response was not ok');
        }

        const responseData = JSON.parse(text);
        console.log(responseData);
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // re-throw the error to be caught and handled outside
    }
}

export async function getOpenGames() {
    try {
        const response = await fetch(`${URL}/game`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        console.log(responseData);
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // re-throw the error to be caught and handled outside
    }
}

export async function getGameDetails(id) {
    try {
        const response = await fetch(`${URL}/game/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();

        console.log(responseData);

        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // re-throw the error to be caught and handled outside
    }
}
