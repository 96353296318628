import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemText,
    TextField,
    MenuItem,
    Paper,
    Stack,
    Divider,
    Modal,
    Box,
    Button,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomButton from './Button';
import { VILLAGERSARR, WOLFARR, TIMERARR } from '../constants';
import { createGame } from './Reqest';

export default function Play() {
    // ローカルストレージからデータを取得
    const storage = JSON.parse(localStorage.getItem('data'));
    const [roomName, setRoomName] = useState(storage ? storage.game_name : '');
    const [villagerCount, setVillagerCount] = useState(
        storage ? storage.villagerCount : 3
    );
    const [werewolfCount, setWerewolfCount] = useState(
        storage ? storage.werewolfCount : 1
    );
    const [timeLimit, setTimeLimit] = useState(storage ? storage.timeLimit : 3);
    const [players, setPlayers] = useState(storage ? storage.players : []);
    const [addName, setAddName] = useState('');
    const [addMail, setAddMail] = useState('');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const navigate = useNavigate();

    const handleButtonClick = async () => {
        setLoading(true);
        const data = {
            game_name: roomName,
            villagerCount: villagerCount,
            werewolfCount: werewolfCount,
            timeLimit: timeLimit,
            players: players,
        };
        // ローカルストレージに保存
        const jsonData = JSON.stringify(data);
        localStorage.setItem('data', jsonData);
        try {
            const createdData = await createGame(jsonData);
            console.log(createdData);
            localStorage.setItem('currentGameData', createdData);
            setAlertSeverity('success');
            setAlertMessage(
                'ルーム情報が更新されました。ゲーム開始画面に遷移します。'
            );
            setTimeout(() => {
                setLoading(false);
                navigate('/standby');
            }, 3000);
        } catch (error) {
            console.error('Failed to create game: ', error);
            setAlertSeverity('error');
            setAlertMessage(
                'ゲームが作成出来ませんでした。トップ画面に遷移します。'
            );
            setTimeout(() => {
                navigate('/');
            }, 3500);
        }
    };

    function addPlayer(nickname, mail) {
        const newPlayer = { nickName: nickname, mail: mail };
        setPlayers((prev) => [...prev, newPlayer]);
    }
    function removeLastPlayer() {
        setPlayers((prev) => {
            const newPlayers = [...prev];
            newPlayers.pop(); // 配列の最後の要素を削除
            return newPlayers;
        });
    }

    return (
        <>
            <Stack
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{ height: 'inherit' }}
            >
                <Paper
                    elevation={10}
                    sx={{
                        minWidth: 320,
                        backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    }}
                >
                    <List>
                        <ListItem sx={{ paddingY: 0 }}>
                            <ListItemText
                                primary="ゲーム設定"
                                secondary="Setting"
                            />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                            <TextField
                                id="outlined-basic"
                                label="Room Name"
                                variant="outlined"
                                fullWidth
                                value={roomName}
                                onChange={(event) =>
                                    setRoomName(event.target.value)
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Villager Count"
                                fullWidth
                                defaultValue="4"
                                value={villagerCount}
                                onChange={(event) => {
                                    setVillagerCount(event.target.value);
                                }}
                            >
                                {VILLAGERSARR.map((option, index) => (
                                    <MenuItem
                                        key={`vil${index}`}
                                        value={option.value}
                                        sx={{ justifyContent: 'center' }}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </ListItem>
                        <ListItem>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Wolf Count"
                                fullWidth
                                defaultValue="4"
                                value={werewolfCount}
                                onChange={(event) => {
                                    setWerewolfCount(event.target.value);
                                }}
                            >
                                {WOLFARR.map((option, index) => (
                                    <MenuItem
                                        key={`wolf${index}`}
                                        value={option.value}
                                        sx={{ justifyContent: 'center' }}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </ListItem>
                        <ListItem>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Timer"
                                fullWidth
                                defaultValue="4"
                                value={timeLimit}
                                onChange={(event) =>
                                    setTimeLimit(event.target.value)
                                }
                            >
                                {TIMERARR.map((option, index) => (
                                    <MenuItem
                                        key={`time${index}`}
                                        value={option.value}
                                        sx={{ justifyContent: 'center' }}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </ListItem>
                        <ListItem
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button onClick={handleOpen}>Select Players</Button>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box
                                    padding={(4, 2)}
                                    sx={{
                                        minWidth: '280px',
                                        backgroundColor:
                                            'rgba(255, 255, 255, 1)',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                >
                                    <ListItemText
                                        primary="参加プレイヤー"
                                        secondary="Setting Players"
                                    />
                                    <hr />
                                    <List
                                        sx={{
                                            paddingX: 2,
                                            paddingY: 0,
                                            minHeight: 30,
                                        }}
                                    >
                                        {players.map((player, index) => (
                                            <ListItemText
                                                key={`player:${player.mail}`}
                                                primary={player.nickName}
                                                secondary={player.mail}
                                            />
                                        ))}
                                    </List>
                                    <hr />
                                    <TextField
                                        id="outlined-basic"
                                        label="NickName"
                                        variant="outlined"
                                        fullWidth
                                        value={addName}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(event) =>
                                            setAddName(event.target.value)
                                        }
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="Mail"
                                        variant="outlined"
                                        fullWidth
                                        value={addMail}
                                        onChange={(event) =>
                                            setAddMail(event.target.value)
                                        }
                                    />
                                    <List>
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Button
                                                variant="outlined"
                                                startIcon={<DeleteIcon />}
                                                disabled={players.length === 0}
                                                onClick={() => {
                                                    removeLastPlayer();
                                                }}
                                                sx={{ fontSize: 10 }}
                                            >
                                                PLAYER削除
                                            </Button>
                                            <Button
                                                variant="contained"
                                                endIcon={<CloudUploadIcon />}
                                                disabled={
                                                    villagerCount +
                                                        werewolfCount ===
                                                    players.length
                                                }
                                                onClick={() => {
                                                    addPlayer(addName, addMail);
                                                    setAddName('');
                                                    setAddMail('');
                                                }}
                                            >
                                                PLAYER追加
                                            </Button>
                                        </Stack>
                                    </List>
                                    <List>
                                        <Stack alignItems={'center'}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    minWidth: '100px',
                                                    fontWeight: '600',
                                                }}
                                                onClick={handleClose}
                                            >
                                                決定
                                            </Button>
                                        </Stack>
                                    </List>
                                </Box>
                            </Modal>
                        </ListItem>
                    </List>
                </Paper>
                <Stack
                    spacing={1}
                    margin-x={4}
                    marginTop={3}
                    paddingX={3}
                    justifyContent="flex-end"
                >
                    {alertMessage && (
                        <Alert severity={alertSeverity}>{alertMessage}</Alert>
                    )}
                    <CustomButton
                        wordE={loading ? 'Loading...' : 'CREATE'}
                        wordJ={loading ? 'ロード中' : 'ルーム作成'}
                        disabled={loading}
                        onClick={handleButtonClick}
                    />
                    <Link to="/">
                        <CustomButton
                            variant="outlined"
                            wordE="BACK"
                            wordJ="戻る"
                        />
                    </Link>
                </Stack>
            </Stack>
        </>
    );
}
