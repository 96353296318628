import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import CustomButton from './Button';
import Loading from './Loading';
import { Paper, Stack, Grid, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { motion } from 'framer-motion';
import { getGameDetails } from './Reqest';
import iconVil from '../img/villager.png';
import iconWolf from '../img/wolf.png';
import iconGod from '../img/god.png';

export default function GameDetail() {
    let { id } = useParams();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [game, setGame] = useState(true);
    const [villagerWord, setVillagerWord] = useState(true);
    const [wolfWord, setWolfWord] = useState(true);
    const [switchState, setSwitch] = useState('');
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setSwitch(newValue);
    };

    function findWord(res, target) {
        const Detail = res['game_details'].find(
            (detail) => detail.role === target
        );
        const Word = Detail.word;
        console.log(Word);

        return Word;
    }

    useEffect(() => {
        const asyncFunction = async () => {
            try {
                const res = await getGameDetails(id);
                setVillagerWord(findWord(res, '村人'));
                setWolfWord(findWord(res, '人狼'));
                setGame(res);
                setLoading(false);
            } catch (error) {
                console.error('Error in fetching open games: ', error);
            }
        };
        asyncFunction();
    }, [location.pathname, id]);

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: 'inherit',
                    }}
                >
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                    >
                        <Paper
                            elevation={10}
                            sx={{
                                padding: 2,
                                minWidth: 280,
                                backgroundColor: 'rgba(255, 255, 255, 0.85)',
                            }}
                        >
                            <Grid
                                container
                                paddingY={2}
                                paddingX={4}
                                spacing={0}
                                marginLeft={2}
                            >
                                <Grid item xs={6}>
                                    <ListItemText
                                        primary={game['game_name']}
                                        secondary="Room Name"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column-reverse',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ListItemText
                                        primary={`${game['limit_time']}分`}
                                        secondary="Timer"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column-reverse',
                                            textAlign: 'center',
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <ListItemText
                                        primary={`${game['villager_num']}人`}
                                        secondary="村人"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column-reverse',
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <ListItemText
                                        primary={`${game['wolf_num']}人`}
                                        secondary="人狼"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column-reverse',
                                            textAlign: 'center',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    {switchState === 'init' && <h2>初期</h2>}
                                    {switchState === 'villagers' && (
                                        <Typography
                                            align="center"
                                            variant={'h5'}
                                        >
                                            村人のお題:
                                            <span>{villagerWord}</span>
                                        </Typography>
                                    )}
                                    {switchState === 'wolf' && (
                                        <Typography
                                            align="center"
                                            variant={'h5'}
                                        >
                                            人狼のお題:
                                            <span>{wolfWord}</span>
                                        </Typography>
                                    )}
                                    {switchState === 'god' && (
                                        <List>
                                            {game['game_details'].map(
                                                (player, index) => (
                                                    <ListItem
                                                        sx={{ padding: 0 }}
                                                        key={index}
                                                    >
                                                        <ListItemText
                                                            primary={`${player.nickName}：${player.role}`}
                                                            secondary={`配られたお題：${player.word}`}
                                                        />
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    )}
                                </Grid>
                            </Grid>
                            <Typography variant={'p'} sx={{ fontSize: '8px' }}>
                                ↓下記の三つのボタンから見たい視点を選んで押してね。
                            </Typography>
                        </Paper>

                        <BottomNavigation
                            sx={{ marginTop: 1 }}
                            value={switchState}
                            onChange={handleChange}
                        >
                            <BottomNavigationAction
                                label="Villagers"
                                value="villagers"
                                icon={
                                    <img
                                        src={iconVil}
                                        alt="vil-icon"
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                        }}
                                    />
                                }
                            />
                            <BottomNavigationAction
                                label="Wolf"
                                value="wolf"
                                icon={
                                    <img
                                        src={iconWolf}
                                        alt="wolf-icon"
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                        }}
                                    />
                                }
                            />
                            <BottomNavigationAction
                                label="God"
                                value="god"
                                icon={
                                    <img
                                        src={iconGod}
                                        alt="god-icon"
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                        }}
                                    />
                                }
                            />
                        </BottomNavigation>
                        <Stack
                            spacing={1}
                            margin-x={4}
                            marginTop={3}
                            paddingX={3}
                            justifyContent="flex-end"
                        >
                            <CustomButton
                                variant="outlined"
                                wordE="BACK"
                                wordJ="戻る"
                                onClick={() => {
                                    navigate('/watch');
                                }}
                            />
                        </Stack>
                    </motion.div>
                </Stack>
            )}
            {}
        </>
    );
}
