export function envSwitch(local, product) {
    const result = window.location.hostname === 'localhost' ? local : product;
    return result;
}

export const URL = envSwitch(
    'http://127.0.0.1:5000',
    'https://bluesharks.xsrv.jp/WordWolfBroadcastsAPI'
);

export const VILLAGERSARR = [
    {
        value: 3,
        label: '村人：3人',
    },
    {
        value: 4,
        label: '村人：4人',
    },
    {
        value: 5,
        label: '村人：5人',
    },
    {
        value: 6,
        label: '村人：6人',
    },
];
export const WOLFARR = [
    {
        value: 1,
        label: '人狼：1人',
    },
    {
        value: 2,
        label: '人狼：2人',
    },
];
export const TIMERARR = [
    {
        value: 3,
        label: '3分',
    },
    {
        value: 4,
        label: '4分',
    },
    {
        value: 5,
        label: '5分',
    },
    {
        value: 6,
        label: '6分',
    },
    {
        value: 7,
        label: '7分',
    },
];
