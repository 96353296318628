import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { Paper, Stack } from '@mui/material';
import { motion } from 'framer-motion';
import { getOpenGames } from './Reqest';
import CustomButton from './Button';
import Loading from './Loading';

export default function Watch() {
    const location = useLocation();
    const [games, setGames] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const loadAsync = async () => {
        try {
            const res = await getOpenGames();
            setGames(res);
            setLoading(false);
        } catch (error) {
            console.error('Error in fetching open games: ', error);
        }
    };

    useEffect(() => {
        if (location.pathname === '/watch') {
            loadAsync();
        }
    }, [location.pathname]);

    return (
        <>
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: 'inherit',
                }}
            >
                {loading && <Loading />}
                {!loading && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                    >
                        <Paper
                            elevation={10}
                            sx={{
                                padding: 2,
                                minWidth: 280,
                                backgroundColor: 'rgba(255, 255, 255, 0.85)',
                            }}
                        >
                            <ListItemText
                                primary="公開ルーム一覧"
                                secondary="Open Rooms"
                            />
                            <List
                                sx={{
                                    width: '100%',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 300,
                                }}
                            >
                                {games.map((game, index) => (
                                    <ListItem disablePadding key={game.id}>
                                        <ListItemButton
                                            onClick={() => {
                                                console.log(game.id);
                                                navigate(`/game/${game.id}`);
                                            }}
                                        >
                                            <ListItemText
                                                primary={game.game_name}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                        <Stack
                            spacing={1}
                            margin-x={4}
                            marginTop={3}
                            paddingX={3}
                            justifyContent="flex-end"
                        >
                            <CustomButton
                                wordE="UPDATE"
                                wordJ="更新"
                                onClick={() => {
                                    navigate(0);
                                }}
                            />
                            <CustomButton
                                variant="outlined"
                                wordE="BACK"
                                wordJ="戻る"
                                onClick={() => {
                                    navigate('/');
                                }}
                            />
                        </Stack>
                    </motion.div>
                )}
            </Stack>
        </>
    );
}
