import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    Alert,
    Grid,
} from '@mui/material';
import CustomButton from './Button';
import { distributionGame } from './Reqest';
import { envSwitch } from '../constants';

export default function Standby() {
    // ローカルストレージからデータを取得
    const storage = JSON.parse(localStorage.getItem('currentGameData'));
    const [gameId, setGameId] = useState(0);
    const [progress, setProgress] = useState('beforeProgress');
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const navigate = useNavigate();

    const handleButtonClick = async () => {
        setLoading(true);
        try {
            const resMsg = await distributionGame(storage);
            console.log(resMsg.game_progress);
            setAlertSeverity('info');
            setAlertMessage('お題が配布されました。議論を開始してください');
            setLoading(false);
            setProgress(resMsg.game_progress);
            console.log(storage.limit_time);
            const time = envSwitch(0.05, storage.limit_time);
            setTimeout(() => {
                setProgress('afterProgress');
                setAlertSeverity('warning');
                setAlertMessage(
                    '議論時間が経過いたしました。投票が完了したら答えを配布してください。'
                );
            }, time * 60 * 1000);
        } catch (error) {
            console.error('Failed to create game: ', error);
            setAlertSeverity('error');
            setProgress('error');
            setAlertMessage(
                'ワードを配る際にエラーが発生しました。ゲーム設定等を見直してください。'
            );
        }
    };
    const handleAnswerClick = async () => {
        setLoading(true);
        const storage = JSON.parse(localStorage.getItem('currentGameData'));
        try {
            const resMsg = await distributionGame(storage);
            setProgress(resMsg.game_progress);
            setGameId(resMsg.update_game_id);
            setLoading(false);
            setAlertSeverity('info');
            setAlertMessage(
                '答えを配布しました。全員のお題と役職を確認したい場合は「神視点を確認する」を押してください。'
            );
        } catch (error) {
            console.error('Failed to create game: ', error);
            setAlertSeverity('error');
            setProgress('error');
            setAlertMessage('答えを配る際にエラーが発生しました。');
        }
    };

    return (
        <>
            <Stack
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ height: 'inherit' }}
            >
                <Paper
                    elevation={10}
                    sx={{
                        minWidth: 320,
                        backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    }}
                >
                    <Grid
                        container
                        paddingY={2}
                        paddingX={4}
                        spacing={0}
                        marginLeft={2}
                    >
                        <Grid item xs={6}>
                            <ListItemText
                                primary={storage['game_name']}
                                secondary="Room Name"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ListItemText
                                primary={`${storage['limit_time']}分`}
                                secondary="Timer"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    textAlign: 'center',
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <ListItemText
                                primary={`${storage['villager_num']}人`}
                                secondary="村人"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <ListItemText
                                primary={`${storage['wolf_num']}人`}
                                secondary="人狼"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    textAlign: 'center',
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <List>
                                {storage.players.map((player, index) => (
                                    <ListItem sx={{ padding: 0 }} key={index}>
                                        <ListItemText
                                            primary={player.nickName}
                                            secondary={player.mail}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
                <Stack
                    spacing={1}
                    margin-x={4}
                    marginTop={3}
                    paddingX={3}
                    justifyContent="flex-end"
                >
                    {alertMessage && (
                        <Alert severity={alertSeverity}>{alertMessage}</Alert>
                    )}
                    {progress === 'beforeProgress' && (
                        <CustomButton
                            wordE={loading ? 'Loading...' : 'DISTRIBUTE'}
                            wordJ={loading ? 'ロード中' : 'ワードを配る'}
                            disabled={loading}
                            onClick={handleButtonClick}
                        />
                    )}
                    {progress === 'afterProgress' && (
                        <CustomButton
                            wordE={loading ? 'Loading...' : 'ANSWER'}
                            wordJ={loading ? 'ロード中' : '答えを配る'}
                            disabled={loading}
                            onClick={handleAnswerClick}
                        />
                    )}
                    {progress === 'end' && (
                        <CustomButton
                            wordE={'ALL ANSWERS'}
                            wordJ={'神視点を見る'}
                            onClick={() => navigate(`/game/${gameId}`)}
                        />
                    )}
                    <CustomButton
                        variant="outlined"
                        wordE="BACK"
                        wordJ="設定に戻る"
                        onClick={() => {
                            navigate('/play');
                        }}
                    />
                </Stack>
            </Stack>
        </>
    );
}
