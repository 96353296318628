import React from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

export default function CustomButton({
    wordE,
    wordJ,
    variant = 'contained',
    color = 'primary',
    onClick,
    disabled,
}) {
    const StyledButton = styled(Button)({
        '&.Mui-disabled': {
            backgroundColor: '#673ab7',
            color: 'white',
        },
    });

    if (variant === 'outlined') {
        color = '#fff';
    }
    if (disabled) {
        color = '#fff';
    }
    return (
        <StyledButton
            fullWidth
            variant={variant}
            color="primary"
            onClick={onClick}
            disabled={disabled}
            sx={{
                flexDirection: 'column',
                backgroundColor: color,
                minWidth: 240,
            }}
        >
            <Typography variant="body1">{wordE}</Typography>
            <Typography
                sx={{ fontSize: 'small', lineHeight: '0.8' }}
                variant="subtitle1"
            >
                {wordJ}
            </Typography>
        </StyledButton>
    );
}
