import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from './Button';

export default function Home() {
    return (
        <Stack
            spacing={2}
            margin-x={4}
            padding-y={4}
            justifyContent="flex-end"
            height="90vh"
        >
            <Link to="/play">
                <Button wordE="PLAY" wordJ="ゲームで遊ぶ" />
            </Link>
            <Link to="/watch">
                <Button wordE="WATCHING" wordJ="ゲームを観戦する" />
            </Link>
        </Stack>
    );
}
