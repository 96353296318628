import React from 'react';
import { Box } from '@mui/material';
import { ThreeCircles } from 'react-loader-spinner';
import { motion } from 'framer-motion';

function Loading() {
    return (
        <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ThreeCircles
                    height="100"
                    width="100"
                    color="#1769aa"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor="#4dabf5"
                    middleCircleColor="#6573c3"
                />
            </Box>
        </motion.div>
    );
}

export default Loading;
